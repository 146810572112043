/* Global */

  .App {
    text-align: center;
    border-style: solid;
    border-color: #345970;
    border-width: medium medium medium medium;
    font-family: 'Dosis', sans-serif;
  }

  .html, .body, #root, .App {
    height: 100%;
    background-color:whitesmoke;
  }

  a {
    color: #345970;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

/* Header */

  .header {
    background-color: #1a1a1d;
    color: #FFFFFF;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
    border-style: solid;
    border-width: 0 0 medium 0;
    border-color: #345970;
  }

/* About/Contact */

  .about-contact-container{
    background-color: whitesmoke;
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
    margin-left: 5%;
    margin-right: 5%;
  }

  .about{
    display:flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  #about-text {
    width: 80%;
    font-size: 1.3em;
    list-style: none;
  }

  .contact-links {
  margin-top: 3em;
  }

/* Projects Layout   */

  .projects-container {
    display: flex;
    flex-direction: row;
    max-width: 95%;
    margin: auto;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .project-grid {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  }

/* Single Project   */

  .single-project {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .project-item {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-image {
    height: auto;
    max-height: auto;
    width: auto;
    max-width: auto;
    width:90%;
    border-radius: 8px;
    border-color: #1a1a1d;
    border: 1px solid;
    box-shadow: 5px 5px 18px #1a1a1d;
  }

  .overlay {
    position: absolute;
    bottom: 100%;
    left: 4%;
    right: 0;
    background-color: #1a1a1d;
    overflow: hidden;
    width: 92%;
    height:0;
    transition: .8s ease;
    border-radius: 8px;
  }

  .project-item:hover .overlay {
    bottom: 0;
    height: 100%;
  }

  .project-title {
    padding-top: 0.5em;
    color: #345970;
    font-size: 2em;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly
  }

  .text {
    color: #FFFFFF;
    position: relative;
    font-weight: bolder;
    top: 50%;
    left: 50%;
    font-style: italic;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }